import axios from 'axios'
import Vue from 'vue'

const ERR_NETWORK_SECONDS_TO_RETRY = 15
const ERR_NETWORK_LAST_RELOAD = 'lastErrNetworkReload'

const setDateAndReload = () => {
  sessionStorage.setItem(ERR_NETWORK_LAST_RELOAD, (new Date()).toISOString())
  window.location.reload()
}

Vue.prototype.$http = process.env.BASE_URL
  ? axios.create({
    baseURL: process.env.BASE_URL,
  })
  : axios

const http = Vue.prototype.$http

http.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = 'unauthorized-access'
      // TODO: I would like to use this but it is breaking the test for the mock of $route and $router
      // router.push({ name: 'UnauthorizedAccess' })
    }

    return Promise.reject(error)
  }
)

export async function getResult<T>(
  url: string,
  params: unknown | undefined = {},
  controller?: AbortController,
): Promise<T> {
  const { data } = await http
    .get(url, { params, signal: controller?.signal })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return { data: null }
      }

      if (error.code && error.code === 'ERR_NETWORK') {
        const lastErrNetworkReload = sessionStorage.getItem(ERR_NETWORK_LAST_RELOAD)
        const lastDate = lastErrNetworkReload ? new Date(lastErrNetworkReload) : null
        const now = new Date()

        if (lastDate) {
          const diffInSeconds = Math.round((now.getTime() - lastDate.getTime()) / 1000)

          if (diffInSeconds >= ERR_NETWORK_SECONDS_TO_RETRY) {
            setDateAndReload()
          } else {
            setTimeout(() => {
              setDateAndReload()
            }, ERR_NETWORK_SECONDS_TO_RETRY * 1000)
          }
        } else {
          // if lastDate is null it means it is the first time
          setDateAndReload()
        }
      } else {
        sessionStorage.removeItem(ERR_NETWORK_LAST_RELOAD)
      }

      throw error
    })

  return data
}

export async function post<T>(
  url: string,
  payload: unknown | undefined,
): Promise<T> {
  const { data } = await http.post(url, payload)

  return data
}

export async function postForm<T>(
  url: string,
  formData: FormData,
): Promise<T> {
  const { data } = await http.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}
